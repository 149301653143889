@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'SF';
    src: url(./fonts/SF-Pro.ttf);
  }

  @font-face {
    font-family: 'Gotham-Black';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./fonts/Gotham-Black.otf);
  }

  @font-face {
    font-family: 'Gotham-Bold';
    src: url(./fonts/Gotham-Bold.otf);
  }

  @font-face {
    font-family: 'Gotham-Medium';
    src: url(./fonts/Gotham-Medium.otf);
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
