.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  text-align: center;
  font-family: 'Arial', sans-serif;
  background-color: #000000;
}

.header {
  padding: 40px 20px;
  height: 75vh;
}

.header h1 {
  font-size: 3rem;
}

.header p {
  font-size: 1.5rem;
  color: #555;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007aff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
}

.features {
  padding: 50px 20px;
  background-color: #fff;
}

.features h2 {
  font-size: 2.5rem;
  color: #333;
}

.feature-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;
}

.feature-item {
  max-width: 300px;
  margin: 20px;
}

.feature-item h3 {
  font-size: 1.5rem;
  color: #007aff;
}

.feature-item p {
  font-size: 1rem;
  color: #666;
}

.download-section {
  background-color: #f8f9fa;
  padding: 50px 20px;
}

.download-section h2 {
  font-size: 2.5rem;
  color: #333;
}

.download-section p {
  font-size: 1.25rem;
  color: #555;
}

.download-link img {
  margin-top: 20px;
  width: 200px;
}

.footer {
  padding: 20px;
  background-color: #333;
  color: #fff;
}

.footer .social-icons a {
  margin: 0 10px;
  color: #007aff;
  text-decoration: none;
}
